import {Injectable} from '@angular/core';
import {SkeletonLoaderService} from '@app/shared/services/skeleton-loader.service';
import {stringFormat} from '@brycemarshall/string-format';
import {BenefitPlanOverview, IndividualPaymentPlanOverview} from '@clux-models';
import {cacheable, withTransaction} from '@datorama/akita';
import {BenefitAccountSummaryByBenefitPlan} from '@models/dashboard/model';
import {flatten} from 'lodash';
import {combineLatest, forkJoin, Observable, of, zip} from 'rxjs';
import {first, map, mapTo, switchMap} from 'rxjs/operators';
import {CommonConstants} from 'src/app/shared/constants/common.constant';
import {Model} from 'src/app/shared/models/clux/model-legacy';
import {BenefitAccountState, ChainType, Criteria, Entry, EntryPosting, EntryType, MatchType, ParentType } from 'src/app/shared/models/uba/account/model';
import {BenefitPlan, BenefitPlanExpenditures, BenefitPlanState, BenefitPlanTotalContributions, PaymentPlanOverview} from 'src/app/shared/models/uba/configuration/model';
import {ServiceFactory} from 'src/app/shared/services/service.factory';
import {ContactQuery} from 'src/app/state';
import {BenefitPlanQuery, BenefitPlanStore} from 'src/app/state/benefit-plan';
import {BenefitPlanFundingSourceAndScheduleQuery} from 'src/app/state/benefit-plan-funding-source-and-schedule';
import {Dates} from '@app/shared/utils/dates';

@Injectable({
  providedIn: 'root',
})
export class OverviewService {
  public constructor(
    private contactQuery: ContactQuery,
    private serviceFactory: ServiceFactory,
    private benefitPlanQuery: BenefitPlanQuery,
    private benefitPlanStore: BenefitPlanStore,
    private benefitPlanFundingSourceAndScheduleQuery: BenefitPlanFundingSourceAndScheduleQuery,
    private skeletonLoaderService: SkeletonLoaderService,
  ) { }

  public getContributionData(
    requestPayload: Model.SearchCriteria,
    profileId: string,
  ): Observable<Model.ContributionsByClient[]> {
    return this.serviceFactory.search<Model.ContributionsByClient[]>(
      CommonConstants.getPath.dashboard.serviceKey,
      CommonConstants.getPath.dashboard.contributionsByClient,
      requestPayload,
      { profileId },
    ).pipe(map((res) => {
      return res.data;
    }));
  }

  public fetchReimbursementData(profileId: string): Observable<Model.ReimbursementsByClient[]> {
    const requestPaylod: Model.SearchCriteria = [{
      key: 'clientId',
      value: profileId,
      matchType: MatchType.EXACT,
      chainType: ChainType.AND,
    }];
    return this.serviceFactory.search<Model.ReimbursementsByClient[]>(
      CommonConstants.getPath.dashboard.serviceKey,
      CommonConstants.getPath.dashboard.reimbursment,
      requestPaylod,
      { profileId },
    ).pipe(map((res) => {
      return res.data;
    }));
  }

  // tslint:disable-next-line:no-any
  public getClientCashBalance(): Observable<any[]> {
    const clientId = this.contactQuery.getActive().clientId;
    const cabBasePath = stringFormat(
      CommonConstants.getPath.profile.cashAccountBalance,
      {
        profileId: clientId,
      },
    );
    const minCabBasePath = stringFormat(
      CommonConstants.getPath.profile.minimumCashAccountBalance,
      {
        profileId: clientId,
      },
    );
    return combineLatest([
      this.serviceFactory.query(CommonConstants.getPath.profile.serviceKey, cabBasePath),
      this.serviceFactory.query(CommonConstants.getPath.profile.configurationKey, minCabBasePath),
    ]);
  }

  public searchEntryPostings(page: number = 0, offset: number = 0, criteria?: Criteria[]):
    Observable<Model.SearchResults<Entry[]>> {
    const clientId = this.contactQuery.getActive().clientId;
    const searchCriteria: Model.SearchCriteria = [
      {
        key: 'profileId',
        value: clientId,
        matchType: MatchType.EXACT,
      },
      {
        key: 'entryType',
        value: [
          'ClientNegativeFunding',
          'FundFromCAB',
        ].join('|'),
        matchType: MatchType.IN,
      },
    ];
    return this.serviceFactory.search<EntryPosting[]>(
      CommonConstants.getPath.account.serviceKey,
      CommonConstants.getPath.account.searchEntryPostings,
      searchCriteria,
      null,
      {
        take: 25,
        skip: 0,
      },
    ).pipe(
      map((res) => {
        return res.data;
      }),
      switchMap((response) => {
        const entryPostingIds: string[] = response.map((e: EntryPosting) => e.parentId);
        return this.searchEntries(entryPostingIds, page, offset, criteria);
      }));
  }

  public loadBenefitPlans(clientId: string): Observable<void> {
    const getPath: Model.ConfigurationPath = CommonConstants.getPath.configuration;
    const plansServiceUrl: string = stringFormat(CommonConstants.getPath.configuration.benefitPlans, {
      profileId: this.contactQuery.getActiveId(),
    });
    const requestPayload: Model.SearchCriteria = [{
      key: 'parentId',
      value: clientId,
      matchType: MatchType.EXACT,
      chainType: ChainType.AND,
    }, {
      key: 'parentType',
      value: ParentType.CLIENT,
      matchType: MatchType.EXACT,
      chainType: ChainType.AND,
    },
    {
      key: 'currentState',
      value: [BenefitPlanState.Active,
      BenefitPlanState.CardsOrdered,
      BenefitPlanState.Draft,
      BenefitPlanState.Finalized,
      BenefitPlanState.FinalizingError,
      BenefitPlanState.FinalizingAccounts,
      BenefitPlanState.FinalizingPlan,
      BenefitPlanState.FinalizationPreCheck,
      BenefitPlanState.GracePeriod,
      BenefitPlanState.Initiated,
      BenefitPlanState.PropagatingDateEdits,
      BenefitPlanState.Reconciliation,
      BenefitPlanState.Removed,
      BenefitPlanState.RolloverComplete,
      BenefitPlanState.RolloverProcessing,
      BenefitPlanState.RolloverProcessingPreCheck,
      BenefitPlanState.RunOut,
      BenefitPlanState.Setup,
      BenefitPlanState.Start].join('|'),
      matchType: MatchType.IN,
    }];
    const request$ = this.serviceFactory.search<BenefitPlan[]>(getPath.serviceKey, plansServiceUrl, requestPayload)
      .pipe(
        withTransaction((benefitPlans) => this.benefitPlanStore.set(benefitPlans.data)),
      );
    return cacheable(this.benefitPlanStore, request$, { emitNext: true })
      .pipe(
        mapTo(null),
      );
  }

  public fetchOverviewTileBenefitPlans(): Observable<BenefitPlanOverview[]> {
    const clientId = this.contactQuery.getActive().clientId;
    const accountSummaryByBPUrl: string = stringFormat(
      CommonConstants.getPath.dashboard.benefitAccountSummaryByBenefitPlan,
      {
        profileId: clientId,
        page: 1000,
        offset: 0,
      },
    );
    const accountSummaryByBPRequestPayload: Criteria[] = [
      {
        key: 'clientId',
        value: clientId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'benefitAccountCurrentState',
        value: BenefitAccountState.Active,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];
    return this.benefitPlanQuery.selectAllWhenLoaded()
      .pipe(
        first(),
        switchMap((benefitPlans) => forkJoin([
          of(benefitPlans),
          this.serviceFactory.search<BenefitAccountSummaryByBenefitPlan[]>(
            CommonConstants.getPath.dashboard.serviceKey,
            accountSummaryByBPUrl,
            accountSummaryByBPRequestPayload,
          ).pipe(map((res) => res.data)),
        ])),
        map(([benefitPlans, benefitPlanEnrollments]) => benefitPlanEnrollments.map<BenefitPlanOverview>((benefitEnrollmentSummary) => ({
          benefitPlan: benefitPlans.find((benefitPlan) => benefitPlan.id === benefitEnrollmentSummary.benefitPlanId),
          benefitEnrollmentSummary,
        }))),
      );
  }

  public loadPaymentPlanOverview(): Observable<IndividualPaymentPlanOverview[]> {
    const clientId = this.contactQuery.getActive().clientId;
    return this.serviceFactory.search<PaymentPlanOverview[]>(
      'configuration',
      `/profile/${clientId}/configuration/paymentPlan/overview`,
      null,
    ).pipe(
      map((results) => {
        return flatten(results.data.map((offeringOverview) => offeringOverview.plans)).map((foo) => {
          return {
            paymentPlanSummary: foo,
          };
        });
      }),
    );
  }

  public fetchPlanOverview(): void {
    const skeletonKey = `plan-overview`;
    this.skeletonLoaderService.add(
      skeletonKey,
      forkJoin([
        this.fetchOverviewTileBenefitPlans(),
        this.loadPaymentPlanOverview(),
      ]).pipe(map(([benefitPlans, paymentPlans]) => ({
        benefitPlans,
        paymentPlans,
      }))),
    );
  }

  public loadRemittanceByPaymentPlan(paymentPlanId: string): void {
    const clientId = this.contactQuery.getActive().clientId;
    const skeletonKey = `remittance-${paymentPlanId}`;

    this.skeletonLoaderService.add(
      skeletonKey,
      this.serviceFactory.query<number>(
        'configuration',
        `/profile/${clientId}/configuration/paymentPlan/${paymentPlanId}/remittance`,
        {},
      ),
    );
  }

  public loadContributionsByBenefitPlan(benefitPlanId: string): void {
    const clientId = this.contactQuery.getActive().clientId;
    const skeletonKey = `contributions-${benefitPlanId}`;

    this.skeletonLoaderService.add(
      skeletonKey,
      this.serviceFactory.query<BenefitPlanTotalContributions>(
        'configuration',
        `/profile/${clientId}/configuration/benefitPlan/${benefitPlanId}/contributions`,
        {},
      ),
    );
  }

  public loadExpendituresByBenefitPlan(benefitPlanId: string): void {
    const clientId = this.contactQuery.getActive().clientId;
    const skeletonKey = `expenditures-${benefitPlanId}`;

    this.skeletonLoaderService.add(
      skeletonKey,
      this.serviceFactory.query<BenefitPlanExpenditures>(
        'configuration',
        `/profile/${clientId}/configuration/benefitPlan/${benefitPlanId}/expenditures`,
        {},
      ),
    );
  }

  // FIXING ISSUES WITH THIS FUNCTION,
  // TODO - THE ENTRY SUMMARY API CALL SHOULD BE REPLACED WITH SIMPLE QUERY AGAINST ENTRY THAT INCLUDES: SCHEDULEDDATE < TODAY AND TAKE 1
  // AND THEN RETURN A BOOLEAN IF WE GET A RESULT
  // ...THIS FUNCTION IS ONLY USED TO KNOW IF A PAST-SCHEDULED ENTRY EXISTS TO DISPLAY A GROWLER MESSAGE (THE ACTUAL DATA IS NOT NEEDED)

  public getManualPostingData(clientId: string): Observable<boolean> {
    return combineLatest([
      this.benefitPlanQuery.selectAllWhenLoaded(),
      this.benefitPlanFundingSourceAndScheduleQuery.selectAllWhenLoaded(),
    ]).pipe(
      switchMap(([benefitPlans, benefitPlanFundingSourceAndSchedules]) => {
        const requiredBenefitPlansCurrentState = new Set(['Active', 'GracePeriod', 'RunOut', 'Reconciliation', 'PropagatingDateEdits']);
        const excludepaymentSourceTypes = new Set(['Check', 'Wire']);
        const manualPostBPFSS = new Set(benefitPlanFundingSourceAndSchedules.filter((x) => x.fundingPostingMethod === 'ManualPost').map((x) => x.parentId));

        const benefitPlanIds = benefitPlans.filter((plan) =>
          requiredBenefitPlansCurrentState.has(plan.currentState)
          && !excludepaymentSourceTypes.has(plan.paymentSourceType)
          && manualPostBPFSS.has(plan.id))
        .map((bp) => bp.id);

        if (benefitPlanIds.length === 0) {
          return of([]);
        }

        const entryTypes = [
          EntryType.ParticipantContribution,
          EntryType.ClientContribution,
          EntryType.ParticipantToClientFunding,
          EntryType.ClientFunding,
          EntryType.PayrollPosting,
        ];

        const summaryRequestPayload: Model.SearchCriteria = [
          {
            key: 'entryType',
            value: entryTypes.join('|'),
            matchType: MatchType.IN,
            chainType: ChainType.AND,
          },
          {
            key: 'benefitPlanId',
            value: benefitPlanIds.join('|'),
            matchType: MatchType.IN,
            chainType: ChainType.AND,
          },
          {
            key: 'currentState',
            value: 'Scheduled',
            matchType: MatchType.EXACT,
            chainType: ChainType.AND,
          },
          {
            key: 'scheduledDate',
            value: Dates.today(),
            matchType: MatchType.LESS_THAN,
            chainType: ChainType.AND,

          },
        ];
        return this.serviceFactory.search<Model.SummaryData[]>(
          CommonConstants.getPath.account.serviceKey,
          `/profile/${clientId}/entry/search`,
          summaryRequestPayload,
          null,
          {
            skip: 0,
            take: 1,
          });
      }),
      map((res: Model.SearchResults<Model.SummaryData[]>) => {
        return res.data && res.data.length > 0;
      }),
    );
  }

  private searchEntries(entryPostingIds: string[], page: number = 0, offset: number = 0, criteria: Criteria[]): Observable<Model.SearchResults<Entry[]>> {
    const clientId = this.contactQuery.getActive().clientId;
    const searchCriteria: Model.SearchCriteria = [
      {
        key: 'id',
        value: entryPostingIds.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
    ];
    let searchEntriesCriteria: Model.SearchCriteria = [];
    if (criteria) {
      searchEntriesCriteria = [...criteria, ...searchCriteria];
    } else {
      searchEntriesCriteria = searchCriteria;
    }
    const queryParams: Model.QueryParams = {
      id: clientId,
    };
    const searchParams: Model.SearchParams = {
      take: page,
      skip: offset,
      orderBy: 'created',
      orderDirection: 'desc',
    };
    return this.serviceFactory.search<Entry[]>(
      CommonConstants.getPath.account.serviceKey,
      CommonConstants.getPath.account.searchEntries,
      searchEntriesCriteria,
      queryParams,
      searchParams,
    );
  }
}
