import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { resetStores } from '@datorama/akita';
import { LoginInfo, LoginType } from '@models/profile/model';
import { AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Observable } from 'rxjs';
import { RaygunService } from 'src/app/raygun/raygun.service';
import { Categories } from 'src/app/shared/constants/analyticsCategory.constant';
import { CommonConstants } from 'src/app/shared/constants/common.constant';
import { CoreService, RememberUserAction, SessionStorage } from 'src/app/shared/models/clux/enum';
import { MatchType } from 'src/app/shared/models/uba/account/model';
import { SearchCriteria } from 'src/app/shared/models/uba/configuration/model';
import { BrowserSessionStorageService } from 'src/app/shared/services/browser-session-storage.service';
import { BrowserService } from 'src/app/shared/services/browser.service';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { SecurityService } from 'src/app/shared/services/security.service';
import { PhoneNumbers } from 'src/app/shared/utils/phone-numbers';
import { Uri } from 'src/app/shared/utils/uri';
import { CurrentUserQuery, CurrentUserStore } from 'src/app/state';
import { environment } from 'src/environments/environment';

import {
  AuthenticationResult,
  AuthenticationResultType,
  ChangePasswordResult,
  ChangePasswordResultType,
  ChangeUserAttributeResult,
  ChangeUserAttributeResultType,
  ConfirmForgotPasswordResult,
  ConfirmForgotPasswordResultType,
  ConfirmUserAttributeResult,
  ConfirmUserAttributeResultType,
  FederatedSignInResult,
  FederatedSignInResultType,
  ForgotPasswordResult,
  ForgotPasswordResultType,
  HubCallback,
  HubCapsule,
  MFAType,
  RefreshSessionResult,
  ResendSignInVerificationCodeResult,
  ResendSignUpVerificationCodeResult,
  SignUpResult,
  SignUpResultType,
  VerifyAuthenticatedUserResult,
  VerifyUserAttributeResult,
  VerifyUserAttributeResultType,
} from '../models';
import { AuthenticationChallenge, AuthenticationChallengeType } from '../models/authentication-challenge.model';
import { BrandService } from '@app/shared/services/brand.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private authenticationDetails?: AuthenticationDetails;
  private cognitoUser?: CognitoUser;
  private cognitoUserSession?: CognitoUserSession;

  public constructor(
    private browserService: BrowserService,
    private browserSessionStorageService: BrowserSessionStorageService,
    private currentUserQuery: CurrentUserQuery,
    private currentUserStore: CurrentUserStore,
    private emitterService: EmitterService,
    private http: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService,
    private raygunService: RaygunService,
    private router: Router,
    private securityService: SecurityService,
    private readonly brandService: BrandService,
  ) {
    this.initializeAmplify();
  }

  /**
   * Changes the users full name associated with their account.
   * @param fullName The new full name the user would like to use.
   */
  public async changeName(fullName: string): Promise<ChangeUserAttributeResult> {
    return this.changeUserAttribute('name', fullName);
  }

  /**
   * Changes the users password to the desired password.
   * @param currentPassword The user's current password.
   * @param desiredPassword The password the user would like to use.
   */
  public async changePassword(currentPassword: string, desiredPassword: string): Promise<ChangePasswordResult> {
    try {
      const changePasswordResult = await Auth.changePassword(this.cognitoUser, currentPassword, desiredPassword);
      if (changePasswordResult === 'SUCCESS') {
        return { type: ChangePasswordResultType.Success };
      }
      this.raygunService.logError('Unexpected changePassword() result', { changePasswordResult });
      return {
        additionalInfo: changePasswordResult,
        type: ChangePasswordResultType.Unsupported,
      };
    } catch (error) {
      if (error && error.code) {
        switch (error.code) {
          case 'InvalidParameterException':
            return {
              error,
              type: ChangePasswordResultType.InvalidDesiredPassword,
            };
          case 'NotAuthorizedException':
            return {
              error,
              type: ChangePasswordResultType.IncorrectCurrentPassword,
            };
          default:
            this.raygunService.logError(error.code, { error });
            return {
              error,
              type: ChangePasswordResultType.UnhandledErrorCode,
            };
        }
      }
      this.raygunService.logError('Change password error', { error });
      return {
        error,
        type: ChangePasswordResultType.Failed,
      };
    }
  }

  /**
   * Changes the users phone number associated with their AWS account.
   * It is recommended that the phone number adhere to the E.164 format +9 (999) 999-9999
   * It is also recommended that phone numbers with extensions adhere to the RFC 3966 format +9 (999) 999-9999;ext=9999
   * @param phoneNumber The new phone number the user would like to use.
   * @see https://openid.net/specs/openid-connect-core-1_0.html#StandardClaims
   */
  public async changeCognitoPhoneNumber(phoneNumber: string): Promise<ChangeUserAttributeResult> {
    const formattedPhoneNumber = PhoneNumbers.formatPhoneNumberForCognito(phoneNumber);
    const changeUserAttributeResult = await this.changeUserAttribute('phone_number', formattedPhoneNumber);
    if (changeUserAttributeResult.type === ChangeUserAttributeResultType.Success) {
      this.setPhoneNumber(formattedPhoneNumber);
    }
    return changeUserAttributeResult;
  }

  /**
   * Checks whether the user has MFA enabled and returns the type of MFA in use (if any).
   */
  public async checkSignInVerificationCodeStatus(): Promise<MFAType> {
    try {
      const mfaStatus = await this.getMFAStatus();
      return this.toMFAType(mfaStatus);
    } catch (error) {
      this.raygunService.logError('Check sign in verification code error', { error });
      return MFAType.Unknown;
    }
  }

  /**
   * Confirm that the user provided the correct verification code.
   * The code will have been received via phone or email, depending on the users preferred MFA type.
   * @param code The verification code.
   */
  public async confirmAuthenticatedUser(code: string): Promise<ConfirmUserAttributeResult> {
    try {
      const mfaType = await this.checkSignInVerificationCodeStatus();
      switch (mfaType) {
        case MFAType.None:
          return this.confirmEmailAddress(code);
        case MFAType.SMS:
          return this.confirmCognitoPhoneNumber(code);
        case MFAType.Unknown:
        case MFAType.Unsupported:
        default:
          return this.confirmEmailAddress(code);
      }
    } catch (error) {
      this.raygunService.logError('Unable to confirm logged in user', { error });
      return {
        error,
        type: ConfirmUserAttributeResultType.Failed,
      };
    }
  }

  /**
   * Confirms that the user provided the correct verification code.
   * The code will have been received via email.
   * @param code The verification code.
   */
  public async confirmEmailAddress(code: string): Promise<ConfirmUserAttributeResult> {
    return this.confirmUserAttribute(code, 'email');
  }

  /**
   * Confirms that the user provided the correct forgot password verification code and resets their password to the desired password.
   * @param code Verification code entered by user.
   * @param desiredPassword The password the user would like to use.
   */
  public async confirmForgotPassword(code: string, desiredPassword: string): Promise<ConfirmForgotPasswordResult> {
    try {
      const emailAddress = this.currentUserQuery.getEmailAddress();
      await Auth.forgotPasswordSubmit(emailAddress, code, desiredPassword);
      return { type: ConfirmForgotPasswordResultType.Success };

    } catch (error) {
      if (error && error.code) {
        switch (error.code) {
          case 'CodeMismatchException':
            return {
              error,
              type: ConfirmForgotPasswordResultType.IncorrectCode,
            };
          case 'ExpiredCodeException':
            return {
              error,
              type: ConfirmForgotPasswordResultType.CodeExpired,
            };
          case 'UserLambdaValidationException':
            return {
              error,
              type: ConfirmForgotPasswordResultType.UserNotFound,
            };
          default:
            this.raygunService.logError(error.code, { error });
            return {
              error,
              type: ConfirmForgotPasswordResultType.UnhandledErrorCode,
            };
        }
      }
      this.raygunService.logError('Forgot password confirmation error', { error });
      return {
        error,
        type: ConfirmForgotPasswordResultType.Failed,
      };
    }
  }

  /**
   * Confirms that the user provided the correct verification code.
   * The code will have been received via phone.
   * @param code The verification code.
   */
  public async confirmCognitoPhoneNumber(code: string): Promise<ConfirmUserAttributeResult> {
    return this.confirmUserAttribute(code, 'phone_number');
  }

  /**
   * Confirms that the user provided the correct sign in verification code and continues the authentication process.
   * The code will have been received via SMS.
   * @see https://aws-amplify.github.io/docs/js/authentication#sign-in
   * @param code The MFA code.
   */
  public async confirmSignIn(code: string): Promise<AuthenticationResult> {
    try {
      const authenticationPromise = Auth.confirmSignIn(this.cognitoUser, code, 'SMS_MFA');
      const authenticationResult = await this.authenticate(authenticationPromise);
      await this.rememberOrForgetDevice();
      return authenticationResult;
    } catch (error) {
      this.raygunService.logError('Sign in confirmation error', { error });
      return {
        error,
        type: AuthenticationResultType.Failed,
      };
    }
  }

  /**
   * Confirms that the user provided the correct sign up verification code and continues the sign up process.
   * The code will have been received via email.
   * @param code The sign up verification code.
   */
  public async confirmSignUp(code: string): Promise<SignUpResult> {
    try {
      const email = this.currentUserQuery.getEmailAddress();
      const confirmSignUpResult = await Auth.confirmSignUp(email, code);
      if (confirmSignUpResult === 'SUCCESS') {
        const authenticationResult = await this.signInWithStoredCredentials();
        const type = authenticationResult && authenticationResult.type === AuthenticationResultType.Success
          ? SignUpResultType.SuccessGoToDetails
          : SignUpResultType.MissingCredentials;
        return {
          authenticationResult,
          type,
        };
      }
      const message = 'Unsupported confirm sign up response.';
      this.raygunService.logError(message, { confirmSignUpResult });
      return {
        additionalInfo: confirmSignUpResult,
        error: new Error(message),
        type: SignUpResultType.Unsupported,
      };
    } catch (error) {
      if (error && error.code) {
        switch (error.code) {
          case 'CodeMismatchException':
            return {
              error,
              type: SignUpResultType.ConfirmationCodeMismatch,
            };
          case 'ExpiredCodeException':
            return {
              error,
              type: SignUpResultType.ConfirmationCodeExpired,
            };
          case 'NotAuthorizedException':
            return {
              error,
              type: SignUpResultType.AlreadyConfirmed,
            };
          default:
            this.raygunService.logError(error.code, { error });
            return {
              error,
              type: SignUpResultType.UnhandledErrorCode,
            };
        }
      }
      this.raygunService.logError('Sign up confirmation error', { error });
      return {
        error,
        type: SignUpResultType.Failed,
      };
    }
  }

  /**
   * Disables SMS-based MFA for future sign in attempts.
   * @returns The preferred type of MFA enabled for the users account, if any.
   */
  public async disableSignInVerificationCodes(): Promise<MFAType> {
    try {
      const mfaStatus = await this.setMFAStatus('NOMFA');
      return this.toMFAType(mfaStatus);
    } catch (error) {
      this.raygunService.logError('Disable sign in verification code error', { error });
      return MFAType.Unknown;
    }
  }

  /**
   * Enables SMS-based MFA for future sign in attempts.
   * @returns The preferred type of MFA enabled for the users account, if any.
   */
  public async enableSignInVerificationCodes(): Promise<MFAType> {
    try {
      const mfaStatus = await this.setMFAStatus('SMS');
      return this.toMFAType(mfaStatus);
    } catch (error) {
      this.raygunService.logError('Enable sign in verification code error', { error });
      return MFAType.Unknown;
    }
  }

  /**
   * Initiates a password reset request. Sends a confirmation code to the users email address or phone, depending on their MFA settings.
   * @param emailAddress The email address of the user that forgot their password.
   */
  public async forgotPassword(emailAddress: string): Promise<ForgotPasswordResult> {
    try {
      const forgotPasswordResult = await Auth.forgotPassword(emailAddress);
      if (forgotPasswordResult.CodeDeliveryDetails && forgotPasswordResult.CodeDeliveryDetails.DeliveryMedium) {
        this.setEmailAddress(emailAddress);

        switch (forgotPasswordResult.CodeDeliveryDetails.DeliveryMedium) {
          case 'EMAIL':
            return {
              additionalInfo: forgotPasswordResult,
              type: ForgotPasswordResultType.ConfirmationCodeSentToEmail,
            };
          case 'SMS':
            if (forgotPasswordResult.CodeDeliveryDetails.Destination) {
              this.setPhoneNumber(forgotPasswordResult.CodeDeliveryDetails.Destination);
            }
            return {
              additionalInfo: forgotPasswordResult,
              type: ForgotPasswordResultType.ConfirmationCodeSentToPhone,
            };
          default:
            this.raygunService.logError('Unhandled forgotPassword() delivery medium', { forgotPasswordResult });
            return {
              additionalInfo: forgotPasswordResult,
              type: ForgotPasswordResultType.ConfirmationCodeSentToUnsupportedDevice,
            };
        }
      }
      this.raygunService.logError('Unexpected forgotPassword() result', { forgotPasswordResult });
      return {
        additionalInfo: forgotPasswordResult,
        type: ForgotPasswordResultType.Unsupported,
      };
    } catch (error) {
      if (error && error.code) {
        switch (error.code) {
          case 'LimitExceededException':
            return {
              error,
              type: ForgotPasswordResultType.LimitExceeded,
            };
          case 'UserNotFoundException':
            return {
              error,
              type: ForgotPasswordResultType.InvalidEmailAddress,
            };
          default:
            this.raygunService.logError(error.code, { error });
            return {
              error,
              type: ForgotPasswordResultType.UnhandledErrorCode,
            };
        }
      }
      this.raygunService.logError('Forgot password error', { error });
      return {
        error,
        type: ForgotPasswordResultType.Failed,
      };
    }
  }

  /**
   * Sends email to the backend, returns what authentication challenge to present to the user.
   * @param username The username to authenticate with. Should be an email address.
   */
  public async getAuthenticationChallengeType(username: string): Promise<AuthenticationChallenge> {
    try {
      const queryData: SearchCriteria = [
        {
          key: 'email',
          value: username,
          matchType: MatchType.EXACT,
        },
        {
          key: 'appType',
          value: 'CU',
          matchType: MatchType.EXACT,
        },
      ];
      const uri = new Uri(`/profile/profileType/activeClientIdp/search`, CoreService.Profile);
      const loginInfos: LoginInfo[] = await this.http.post<LoginInfo[]>(uri.toString(), queryData).toPromise();
      if (loginInfos && loginInfos.length > 0) {
        if (loginInfos.length > 1) {
          const multipleIdentityProvidersError = new Error('Multiple identity providers given. Defaulting to the first identity provider.');
          this.raygunService.logError(multipleIdentityProvidersError, { loginInfos });
        }
        const loginInfo: LoginInfo = loginInfos[0];
        if (loginInfo.loginType === LoginType.SAML) {
          if (loginInfo.identityProvider) {
            return { challengeType: AuthenticationChallengeType.SAML, identityProvider: loginInfo.identityProvider, idpOverrides: loginInfo.idpOverrides };
          }
          const noIdentityProviderError = new Error('System is configured for SAML authentication but no identity provider is specified.');
          this.raygunService.logError(noIdentityProviderError, { loginInfo });
          return { challengeType: AuthenticationChallengeType.Unknown, error: noIdentityProviderError };
        }
      }
      return { challengeType: AuthenticationChallengeType.Password };
    } catch (error) {
      this.raygunService.logError('Authentication challenge type error', { error, username });
      return {
        error,
        challengeType: AuthenticationChallengeType.Failed,
      };
    }
  }

  /**
   * Indicates whether the current user has a valid Cognito User Session.
   */
  public hasValidSession(): boolean {
    return !!this.cognitoUserSession && this.cognitoUserSession.isValid();
  }

  /**
   * Adds a listener for the auth event from the Amplify Hub.
   */
  public listenForFederatedAuthentication(): void {
    // Hub.listen is an amplify library that absorbs the code token and sends it to the cognito API to grab the Access/Id/Refresh Token.
    // We listen on this channel to verify this is complete and before we confirm the user is authenticated.
    const hubCallback: HubCallback = (capsule: HubCapsule) => {
      this.signInFederatedUser(capsule)
        .then(async (federatedSignInResult) => {
          // Because amplify redirects to the redirectSignOut after auth.signOut, we have to save the error to session and set the toastr in logout/federated
          if (federatedSignInResult.type === FederatedSignInResultType.DifferentUserReturned) {
            this.browserSessionStorageService.set(SessionStorage.FederatedError, 'Please sign out and sign in again through your employer portal.');
            await this.router.navigate(['/logout']);
          } else if (federatedSignInResult.type !== FederatedSignInResultType.Success) {
            this.browserSessionStorageService.set(SessionStorage.FederatedError, 'Something went wrong');
            await this.router.navigate(['/logout']);
          }
          Hub.remove('auth', hubCallback);
        })
        .catch((error) => this.raygunService.logError(error));
    };
    Hub.listen('auth', hubCallback);
  }

  /**
   * Navigates to the appropriate route based on the provided authentication result.
   * @param authenticationResult The result of a previous authentication attempt.
   */
  public async navigateBasedOnAuthentication(authenticationResult: AuthenticationResult): Promise<boolean> {
    switch (authenticationResult.type) {
      case AuthenticationResultType.Failed:
      case AuthenticationResultType.UnhandledChallenge:
      case AuthenticationResultType.UnhandledErrorCode:
      case AuthenticationResultType.UnsupportedChallenge:
        this.googleAnalyticsService.eventEmitter(Categories.EventCategories.SignInFail);
        this.raygunService.logError('Authentication failed', { authenticationResult });
        return this.router.navigate(['/logout']);
      case AuthenticationResultType.IncorrectPassword:
        const errorCode = authenticationResult.error['code'];
        const emitterMsg = this.emitterService.getEmitterMessage(CommonConstants.errorCodes[errorCode], false);
        this.emitterService.on(CommonConstants.emmiterKeys.loginErrors).emit(emitterMsg);
        return this.router.navigate(['/login']);
      case AuthenticationResultType.Timeout:
        return this.router.navigate(['/login']);
      case AuthenticationResultType.UserNotFound:
        return this.router.navigate(['/signup/userNotExist']);
      case AuthenticationResultType.IncorrectMFACode:
      case AuthenticationResultType.MFACodeRequired:
        return this.router.navigate(['/for2FA/verify-2FA-mobile']);
      case AuthenticationResultType.PasswordResetRequired:
        return this.router.navigate(['/forgot-password']);
      case AuthenticationResultType.PasswordUpdateRequired:
        return this.router.navigate(['/login/new-password']);
      case AuthenticationResultType.Success:
        if (this.currentUserQuery.getNumberOfClientsFromToken() > 1) {
          return this.router.navigate(['distributor']);
        }

        // if number of clients <= 1, we should have already selected a client from the distributor component or defaulted to the only client
        // so at this point, if there is no result from getClientIdFromToken(), that means the user has no valid clients for the current tpa partner domain
        // an example of when this could happen is when a contact has only TASC clients but tries to log in on the BASIC website
        if (!this.currentUserQuery.getClientIdFromToken()) {
          const noClientsEmitterMsg = this.emitterService.getEmitterMessage('The email and password combination you entered is incorrect. Please try again', false);
          this.emitterService.on(CommonConstants.emmiterKeys.loginErrors).emit(noClientsEmitterMsg);
          return this.router.navigate(['/login']);
        }

        this.googleAnalyticsService.eventEmitter(Categories.EventCategories.SignInSuccess);
        const url = this.browserSessionStorageService.get<string>(SessionStorage.Url);
        if (url) {
          this.browserSessionStorageService.remove(SessionStorage.Url);
          return this.router.navigate([url]);
        }
        return this.router.navigate(['/home']);
      case AuthenticationResultType.UserConfirmationRequired:
        await this.resendSignUpVerificationCode();
        return this.router.navigate(['/signup/verify-email']);
    }
    this.raygunService.logError('Unhandled AuthenticationResult', { authenticationResult });
    return this.router.navigate(['/logout']);
  }

  /**
   * Navigates to the appropriate route based on the provided sign up result.
   * @param signUpResult The result of a sign up attempt.
   */
  public async navigateBasedOnSignUp(signUpResult: SignUpResult): Promise<boolean> {
    switch (signUpResult.type) {
      case SignUpResultType.ConfirmationCodeExpired:
        await this.resendSignUpVerificationCode();
        return this.router.navigate(['/signup/verify-email']);
      case SignUpResultType.EmailConfirmationRequired:
        return this.router.navigate(['/signup/verify-email']);
      case SignUpResultType.Failed:
      case SignUpResultType.UnhandledErrorCode:
      case SignUpResultType.Unsupported:
      case SignUpResultType.ValidationFailed:
        this.raygunService.logError('Sign up failed', { signUpResult });
        return this.router.navigate(['/logout']);
      case SignUpResultType.NotAllowed:
        return this.router.navigate(['/signup/userNotExist']);
      case SignUpResultType.MissingCredentials:
      case SignUpResultType.UserAlreadyExists:
        return this.router.navigate(['/login']);
      case SignUpResultType.SuccessGoToDetails:
        return this.router.navigate(['/signup/userDetails']);
      case SignUpResultType.AlreadyConfirmed:
      case SignUpResultType.Success:
        return this.router.navigate(['/signup/successSignup']);
    }
    this.raygunService.logError('Unhandled SignUpResult', { signUpResult });
    return this.router.navigate(['/logout']);
  }

  /**
   * Redirect the user to the Cognito SAML flow
   * @param identityProvider
   */
  public redirectToFederatedSignInPage(identityProvider: string, emailAddress?: string, federatedIDURL?: string): void {
    if (emailAddress) {
      this.browserSessionStorageService.set(SessionStorage.Email, emailAddress);
    }
    const redirectUriBase = this.browserService.getLocationOrigin();
    const federatedSignInURL = federatedIDURL || new Uri(`/oauth2/authorize?identity_provider=${identityProvider}&redirect_uri=${redirectUriBase}/login/federated&response_type=CODE&client_id=${environment.auth.ClientId}`, CoreService.Cognito);
    this.browserService.redirectToPage(federatedSignInURL.toString());
  }

  /**
   * Refreshes the current user session if needed and returns a value indicating whether the session is still valid.
   */
  public async refreshSession(): Promise<RefreshSessionResult> {
    try {
      const hasValidSession = this.hasValidSession();
      if (hasValidSession) {
        return { isValidSession: true };
      }

      await this.setCognitoUserFromAmplify();
      await this.setCurrentUserFromAmplify();
      return { isValidSession: !!this.cognitoUserSession && this.cognitoUserSession.isValid() };
    } catch (error) {
      return {
        error,
        isValidSession: false,
      };
    }
  }

  /**
   * Resends an MFA sign in verification code via SMS.
   * Note: Due to limitations of the Amplify library, this method will attempt to sign the user in to force a new code to be sent.
   */
  public async resendSignInVerificationCode(): Promise<ResendSignInVerificationCodeResult> {
    try {
      const authenticationResult = await this.signInWithStoredCredentials();
      const wasCodeSent = authenticationResult.type === AuthenticationResultType.MFACodeRequired
        || authenticationResult.type === AuthenticationResultType.UserConfirmationRequired;
      return {
        authenticationResult,
        wasCodeSent,
      };
    } catch (error) {
      this.raygunService.logError('Unable to resend sign in verification code', { error });
      return {
        error,
        wasCodeSent: false,
      };
    }
  }

  /**
   * Resends a sign up verification code to email to complete an abandoned sign up workflow.
   * @example User closes window after receiving initial email verification code.
   */
  public async resendSignUpVerificationCode(): Promise<ResendSignUpVerificationCodeResult> {
    try {
      const email = this.currentUserQuery.getEmailAddress();
      const resendEmailResult = await Auth.resendSignUp(email); // Returns an object (docs incorrectly say this returns a string)
      const wasCodeSent = resendEmailResult && !!resendEmailResult.CodeDeliveryDetails;
      if (wasCodeSent) {
        const emitterMsg = this.emitterService.getEmitterMessage(`Verification code sent to ${email}. This code is valid for 24 hours.`, true);
        this.emitterService.on(CommonConstants.emmiterKeys.loginErrors).emit(emitterMsg);
      }
      return {
        codeDeliveryDetails: resendEmailResult ? resendEmailResult.CodeDeliveryDetails : null,
        wasCodeSent,
      };
    } catch (error) {
      this.raygunService.logError('Unable to resend sign up verification code', { error });
      return {
        error,
        wasCodeSent: false,
      };
    }
  }

  /**
   * Authenticates a username and password against the AWS Cognito User Pool.
   * See documentation at https://aws-amplify.github.io/docs/js/authentication#sign-in
   * @param username The username to authenticate with. Should be an email address.
   * @param password The password to authenticate with. Should not be empty.
   * @param rememberUser Indicates whether the user should be remembered.
   */
  public async signIn(username: string, password: string, rememberUser: RememberUserAction): Promise<AuthenticationResult> {
    await this.signOut();
    const authenticationPromise = Auth.signIn(username, password, { tpaPartner: this.brandService.getTpaPartner() });
    const authenticationResult = await this.authenticate(authenticationPromise);
    switch (authenticationResult.type) {
      case AuthenticationResultType.MFACodeRequired:
      case AuthenticationResultType.PasswordResetRequired:
      case AuthenticationResultType.PasswordUpdateRequired:
      case AuthenticationResultType.Success:
      case AuthenticationResultType.UserConfirmationRequired:
        this.authenticationDetails = new AuthenticationDetails({ Username: username, Password: password });
        this.setEmailAddress(username);
        this.setAuthenticationType(AuthenticationChallengeType.Password);
        if (rememberUser === RememberUserAction.Remember) {
          this.currentUserStore.rememberUser(username);
        } else if (rememberUser === RememberUserAction.Forget) {
          this.currentUserStore.forgetUser();
        }
        await this.rememberOrForgetDevice();
        break;
      default:
        this.setEmailAddress(null);
        break;
    }
    return authenticationResult;
  }

  /**
   * Sets the user authentication type to SAML and refreshes the session
   */
  public async signInFederatedUser(data: HubCapsule): Promise<FederatedSignInResult> {
    if (!data || !data.payload || !data.payload.event) {
      this.raygunService.logError('Single sign on callback received unexpected parameter', { data });
      return { type: FederatedSignInResultType.Failed };
    }
    switch (data.payload.event) {
      case 'signIn':
        this.setAuthenticationType(AuthenticationChallengeType.SAML);
        const authenticationResult = await this.refreshSession();
        if (authenticationResult.isValidSession) {
          const storedSessionEmail: string = this.browserSessionStorageService.get(SessionStorage.Email);
          const currentUserEmail: string = await this.getUserAttribute('email');
          if (storedSessionEmail && currentUserEmail && storedSessionEmail.toLowerCase() !== currentUserEmail.toLowerCase()) {
            this.raygunService.logError('Single sign on email entered is not the same as the email returned from federated user', { data });
            return { type: FederatedSignInResultType.DifferentUserReturned };
          }
          await this.navigateBasedOnAuthentication({ type: AuthenticationResultType.Success });
          return { type: FederatedSignInResultType.Success };
        }

        this.raygunService.logError('Single sign on resulted in invalid session', { data });
        return { type: FederatedSignInResultType.InvalidSession };
      // This event is called after signIn
      case 'cognitoHostedUI':
        return { type: FederatedSignInResultType.Success };
    }

    this.raygunService.logError('Single sign on callback received unrecognized event type', { data });
    return { type: FederatedSignInResultType.Failed };
  }

  /**
   * Signs the user out of the current device and session.
   */
  public async signOut(): Promise<void> {
    try {
      await Auth.signOut();
      this.cognitoUser = this.cognitoUserSession = undefined;

      const sessionStorageToClear: string[] = [SessionStorage.Email, CommonConstants.localKeys.showPostManuallyGrowl,
        CommonConstants.localKeys.showHoldGrowler];
      sessionStorageToClear.map((key) => this.browserSessionStorageService.remove(key));

      resetStores();
      this.raygunService.clearUser();
    } catch (error) {
      this.raygunService.logError('Sign out failed', { error });
      throw error;
    }
  }

  /**
   * Attempts to create a new user account in the AWS Cognito User Pool.
   * @param emailAddress The email address of the user (will also be their username).
   * @param password The password chosen by the user.
   */
  public async signUp(emailAddress: string, password: string): Promise<SignUpResult> {
    try {
      await this.signOut();
      this.setEmailAddress(emailAddress);
      const params = {
        username: emailAddress,
        password,
        attributes: {
          email: emailAddress,
        },
      };
      const signUpResult = await Auth.signUp(params);
      this.authenticationDetails = new AuthenticationDetails({ Username: emailAddress, Password: password });
      if (signUpResult.userConfirmed) {
        return { type: SignUpResultType.Success };
      } else if (signUpResult.codeDeliveryDetails && signUpResult.codeDeliveryDetails.DeliveryMedium === 'EMAIL') {
        return { type: SignUpResultType.EmailConfirmationRequired };
      } else {
        const message = 'Unsupported sign up response.';
        this.raygunService.logError(message, { signUpResult });
        return {
          additionalInfo: JSON.stringify(signUpResult),
          error: new Error(message),
          type: SignUpResultType.Unsupported,
        };
      }
    } catch (error) {
      if (error && error.code) {
        switch (error.code) {
          case 'InvalidParameterException':
            return {
              error,
              type: SignUpResultType.ValidationFailed,
            };
          case 'UserLambdaValidationException':
            return {
              error,
              type: SignUpResultType.NotAllowed,
            };
          case 'UsernameExistsException':
            this.authenticationDetails = new AuthenticationDetails({ Username: emailAddress, Password: password });
            return {
              error,
              type: SignUpResultType.UserAlreadyExists,
            };
          default:
            this.raygunService.logError(error.code, { error });
            return {
              error,
              type: SignUpResultType.UnhandledErrorCode,
            };
        }
      }
      this.raygunService.logError('Sign up error', { error });
      return {
        error,
        type: SignUpResultType.Failed,
      };
    }
  }

  /**
   * Continues authenticating a user with a new password.
   * See documentation at https://aws-amplify.github.io/docs/js/authentication#sign-in
   * @param newPassword The new password for the user.
   */
  public async updatePasswordDuringSignIn(newPassword: string): Promise<AuthenticationResult> {
    try {
      const authenticationPromise = Auth.completeNewPassword(this.cognitoUser, newPassword, null);
      const authenticationResult = await this.authenticate(authenticationPromise);
      return authenticationResult;
    } catch (error) {
      return {
        error,
        type: AuthenticationResultType.Failed,
      };
    }
  }

  /**
   * Sends a verification code to the users email.
   */
  public async verifyEmailAddress(): Promise<VerifyUserAttributeResult> {
    return this.verifyUserAttribute('email');
  }

  /**
   * Sends a verification code to the users phone.
   */
  public async verifyCognitoPhoneNumber(): Promise<VerifyUserAttributeResult> {
    return this.verifyUserAttribute('phone_number');
  }

  public getEmailFromAuth0Code(code: string, state: string): Observable<string> {
    try {
      return this.securityService.getEmailFromAuth0Code(code, state);
    } catch (error) {
      this.raygunService.logError('Auth0 code error', { error });
      throw error;
    }
  }

  /**
   * Authenticates the user using stored credentials.
   * @param clientId Specifies the client. Useful when a contact is associated with multiple clients.
   */
  public async signInWithStoredCredentials(clientId?: string): Promise<AuthenticationResult> {
    if (this.authenticationDetails) {
      await this.signOut();
      const username = this.authenticationDetails.getUsername();
      const password = this.authenticationDetails.getPassword();
      const authenticationPromise = Auth.signIn(username, password, {
        tpaPartner: this.brandService.getTpaPartner(),
        selectedClientId: clientId,
      });

      const authenticationResult = this.authenticate(authenticationPromise);
      this.setEmailAddress(username);
      return authenticationResult;
    }

    this.raygunService.logError('Stored credentials not found');
    return Promise.resolve({
      type: AuthenticationResultType.IncorrectPassword,
    });
  }

  // tslint:disable-next-line: no-any
  private async authenticate(authenticationPromise: Promise<CognitoUser | any>): Promise<AuthenticationResult> {
    try {
      const user = this.cognitoUser = await authenticationPromise;
      if (!user.signInUserSession && user.challengeName) {
        switch (user.challengeName) {
          case 'MFA_SETUP':
            // This happens when the MFA method is TOTP
            // The user needs to setup the TOTP before using it
            // More info please check the Enabling MFA part
            return {
              error: new Error('TOTP MFA is not supported.'),
              type: AuthenticationResultType.UnsupportedChallenge,
            };
          case 'NEW_PASSWORD_REQUIRED':
            // the array of required attributes, e.g ['email', 'phone_number']
            const requiredAttributes = user.challengeParam;
            if (requiredAttributes && requiredAttributes.length && requiredAttributes.some((attr) => attr !== 'email' && attr !== 'phone_number')) {
              return {
                additionalInfo: user.challengeParam,
                error: new Error('Required attributes are not supported.'),
                type: AuthenticationResultType.UnsupportedChallenge,
              };
            }
            return { type: AuthenticationResultType.PasswordUpdateRequired };
          case 'SMS_MFA':
          case 'SOFTWARE_TOKEN_MFA':
            // If MFA is enabled, sign-in should be confirmed with the confirmation code
            // await this.setCurrentUser(user.);
            if (user.challengeParam && user.challengeParam.CODE_DELIVERY_DELIVERY_MEDIUM === 'SMS') {
              const maskedPhoneNumber = user.challengeParam.CODE_DELIVERY_DESTINATION as string;
              this.setPhoneNumber(maskedPhoneNumber);
            }
            return { type: AuthenticationResultType.MFACodeRequired };
          default:
            return {
              additionalInfo: user.challengeName,
              type: AuthenticationResultType.UnhandledChallenge,
            };
        }
      }

      await this.setCognitoUserFromAmplify();
      await this.setCurrentUserFromAmplify();
      return { type: AuthenticationResultType.Success };
    } catch (error) {
      if (error && error.code) {
        switch (error.code) {
          case 'CodeMismatchException':
            // The error happens when the incorrect MFA code is provided
            return {
              error,
              type: AuthenticationResultType.IncorrectMFACode,
            };
          case 'NetworkError':
            // This error happens when Cognito is unreachable or one of the authentication triggers times out
            this.raygunService.logError('Cognito timeout', { error });
            return {
              error,
              type: AuthenticationResultType.Timeout,
            };
          case 'NotAuthorizedException':
            // The error happens when the incorrect password is provided
            return {
              error,
              type: AuthenticationResultType.IncorrectPassword,
            };
          case 'PasswordResetRequiredException':
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
            return {
              error,
              type: AuthenticationResultType.PasswordResetRequired,
            };
          case 'UserNotConfirmedException':
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user
            // About how to resend the code and confirm the user, please check the signUp part
            return {
              error,
              type: AuthenticationResultType.UserConfirmationRequired,
            };
          case 'UserNotFoundException':
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            return {
              error,
              type: AuthenticationResultType.UserNotFound,
            };
          default:
            this.raygunService.logError(error.code, { error });
            return {
              error,
              type: AuthenticationResultType.UnhandledErrorCode,
            };
        }
      }

      this.raygunService.logError('Authentication error', { error });
      return {
        error,
        type: AuthenticationResultType.Failed,
      };
    }
  }

  private async changeUserAttribute(attributeName: string, value: string): Promise<ChangeUserAttributeResult> {
    try {
      const attributeChanges = { [attributeName]: value };
      await this.setCognitoUserFromAmplify();
      const updateUserAttributesResult = await Auth.updateUserAttributes(this.cognitoUser, attributeChanges);
      if (updateUserAttributesResult === 'SUCCESS') {
        return { type: ChangeUserAttributeResultType.Success };
      }
      const message = 'Unsupported update user attribute response.';
      this.raygunService.logError(message, { updateUserAttributesResult });
      return {
        additionalInfo: updateUserAttributesResult,
        error: new Error(message),
        type: ChangeUserAttributeResultType.Unsupported,
      };
    } catch (error) {
      this.raygunService.logError('Change user attribute error', { error });
      return {
        error,
        type: ChangeUserAttributeResultType.Failed,
      };
    }
  }

  private async confirmUserAttribute(code: string, attribute: string): Promise<ConfirmUserAttributeResult> {
    try {
      const confirmUserAttributeResult = await Auth.verifyUserAttributeSubmit(this.cognitoUser, attribute, code);
      if (confirmUserAttributeResult === 'SUCCESS') {
        return { type: ConfirmUserAttributeResultType.Success };
      }
      const message = 'Unsupported verify user attribute response.';
      this.raygunService.logError(message, { confirmUserAttributeResult });
      return {
        additionalInfo: confirmUserAttributeResult,
        error: new Error(message),
        type: ConfirmUserAttributeResultType.Unsupported,
      };
    } catch (error) {
      if (error && error.code) {
        switch (error.code) {
          case 'CodeMismatchException':
            return {
              error,
              type: ConfirmUserAttributeResultType.IncorrectCode,
            };
          case 'ExpiredCodeException':
            return {
              error,
              type: ConfirmUserAttributeResultType.CodeExpired,
            };
          default:
            this.raygunService.logError(error.code, { error });
            return {
              error,
              type: ConfirmUserAttributeResultType.UnhandledErrorCode,
            };
        }
      }
      this.raygunService.logError('Confirm user attribute error', { error });
      return {
        error,
        type: ConfirmUserAttributeResultType.Failed,
      };
    }
  }

  private async getMFAStatus(): Promise<string> {
    try {
      // this will throw if the user has SMS MFA enabled but it is not set to preferred as of Amplify v2.2.0
      const preferredMFAType = await Auth.getPreferredMFA(this.cognitoUser, { bypassCache: true });
      return preferredMFAType;
    } catch (preferredMFAError) {
      // this is the error message that indicates the user may have SMS MFA enabled but not preferred
      if (preferredMFAError === 'invalid MFA Type') {
        const refreshSessionResult = await this.refreshSession();
        if (!refreshSessionResult.isValidSession) {
          throw refreshSessionResult.error || new Error('Invalid session.');
        }
        const mfaTypePromise = new Promise<string>((resolve, reject) => {
          try {
            this.cognitoUser.getUserData((userDataCallbackError, userData) => {
              if (userDataCallbackError) {
                reject(userDataCallbackError);
              } else {
                const mfaType = userData && userData.MFAOptions ? 'SMS' : 'NOMFA';
                resolve(mfaType);
              }
            });
          } catch (userDataError) {
            reject(userDataError);
          }
        });
        return mfaTypePromise;
      }

      throw preferredMFAError;
    }
  }

  private async getUserAttribute(attributeName: string): Promise<string> {
    try {
      const localAttributes = this.cognitoUser['attributes'];
      if (localAttributes) {
        return localAttributes[attributeName];
      }
      const userAttributes = await Auth.userAttributes(this.cognitoUser);
      const userAttribute = userAttributes.find((attribute) => attribute.getName() === attributeName);
      if (userAttribute) {
        return userAttribute.getValue();
      }
      return null;
    } catch (error) {
      this.raygunService.logError('Get user attribute error', { error });
      return null;
    }
  }

  private async rememberOrForgetDevice(): Promise<void> {
    const refreshSessionResult = await this.refreshSession();
    if (!refreshSessionResult.isValidSession) {
      return;
    }
    const rememberDevicePromise = new Promise<void>((resolve, reject) => {
      try {
        const callbacks = {
          // tslint:disable-next-line: no-any
          onFailure: (error: any) => {
            reject(error);
          },
          onSuccess: (result: string) => {
            if (result === 'SUCCESS') {
              resolve();
            } else {
              reject(result);
            }
          },
        };
        // this is a private method, but it needs to be called before we can set the device status
        this.cognitoUser['getCachedDeviceKeyAndPassword']();
        if (this.currentUserQuery.isRememberUserEnabled()) {
          this.cognitoUser.setDeviceStatusRemembered(callbacks);
        } else {
          this.cognitoUser.setDeviceStatusNotRemembered(callbacks);
        }
      } catch (error) {
        reject(error);
      }
    });
    try {
      await rememberDevicePromise;
    } catch (error) {
      this.raygunService.logError('Remember or forget device failed', { error });
    }
  }

  private async setCognitoUserFromAmplify(): Promise<void> {
    if (this.cognitoUser) {
      return;
    }
    this.cognitoUser = await Auth.currentAuthenticatedUser();
    const email = await this.getUserAttribute('email');
    this.setEmailAddress(email);
  }

  private async setCurrentUser(cognitoUserSession: CognitoUserSession): Promise<void> {
    this.cognitoUserSession = cognitoUserSession;
    if (this.cognitoUserSession) {
      const phoneNumber = await this.getUserAttribute('phone_number');
      this.setPhoneNumber(phoneNumber);
      this.currentUserStore.update({
        accessToken: this.cognitoUserSession.getAccessToken(),
        refreshToken: this.cognitoUserSession.getRefreshToken(),
        idToken: this.cognitoUserSession.getIdToken(),
      });
    } else {
      this.currentUserStore.update({
        accessToken: null,
        refreshToken: null,
        idToken: null,
      });
    }
  }

  private async setCurrentUserFromAmplify(): Promise<void> {
    const cognitoUserSession = await Auth.currentSession();
    await this.setCurrentUser(cognitoUserSession);
  }

  private setEmailAddress(emailAddress: string): void {
    if (emailAddress) {
      this.raygunService.setUser(emailAddress, emailAddress);
      this.currentUserStore.update({
        email: emailAddress,
      });
    } else {
      this.currentUserStore.reset();
      this.raygunService.clearUser();
    }
  }

  private setPhoneNumber(phoneNumber: string): void {
    const formattedPhoneNumber = phoneNumber ? PhoneNumbers.formatPhoneNumberForUBA(phoneNumber) : null;
    this.currentUserStore.update({
      cognitoPhoneNumber: formattedPhoneNumber,
    });
  }

  private setAuthenticationType(authenticationChallengeType: AuthenticationChallengeType): void {
    this.currentUserStore.update({
      authenticationType: authenticationChallengeType,
    });
  }

  private toMFAType(mfaStatus: string): MFAType {
    switch (mfaStatus) {
      case 'NOMFA':
        return MFAType.None;
      case 'TOTP':
        this.raygunService.logError('TOTP verification is not supported', { mfaStatus });
        return MFAType.Unsupported;
      case 'SMS':
      case 'SMS_MFA':
        return MFAType.SMS;
      default:
        this.raygunService.logError('Unhandled MFA type', { mfaStatus });
        return MFAType.Unsupported;
    }
  }

  private async setMFAStatus(mfaMethod: 'NOMFA' | 'SMS'): Promise<string> {
    try {
      const setPreferredMFAResult = await Auth.setPreferredMFA(this.cognitoUser, mfaMethod);
      if (setPreferredMFAResult === 'SUCCESS' || setPreferredMFAResult === 'No change for mfa type') {
        return mfaMethod;
      }
      this.raygunService.logError('Unexpected setPreferredMFA() result', { setPreferredMFAResult });
      return this.getMFAStatus();
    } catch (error) {
      if (error.message === 'Invalid MFA type' && mfaMethod === 'NOMFA') {
        // We'll get here when user is disabling MFA and it had been enabled under the old Cognito SDK (pre-Amplify).
        // Enable it as a preferred method; then we can disable.
        await this.setMFAStatus('SMS');
        return this.setMFAStatus(mfaMethod);
      }
      this.raygunService.logError('Set MFA status error', { error });
      return this.getMFAStatus();
    }
  }

  private async verifyUserAttribute(attribute: string): Promise<VerifyUserAttributeResult> {
    try {
      await Auth.verifyUserAttribute(this.cognitoUser, attribute);
      return { type: VerifyUserAttributeResultType.Success };
    } catch (error) {
      if (error && error.code) {
        switch (error.code) {
          default:
            this.raygunService.logError(error.code, { error });
            return {
              error,
              type: VerifyUserAttributeResultType.UnhandledErrorCode,
            };
        }
      }
      this.raygunService.logError('Verify user attribute error', { error });
      return {
        error,
        type: VerifyUserAttributeResultType.Failed,
      };
    }
  }

  private initializeAmplify(): void {
    const redirectUriBase = this.browserService.getLocationOrigin();
    const cognitoURL = new Uri('', CoreService.Cognito);
    const pathname = this.browserService.getLocationPathname();
    const isIdPi = pathname === '/login/federatedIdPi';
    Auth.configure({
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

      // REQUIRED - Amazon Cognito Region
      region: 'us-east-1',

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'XX-XXXX-X',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: environment.auth.UserPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: isIdPi ?  environment.auth.idpiClientId : environment.auth.ClientId,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //   domain: environment.auth.endpoint,
      //   // OPTIONAL - Cookie path
      //   path: '/',
      //   // OPTIONAL - Cookie expiration in days
      //   expires: 365,
      //   // OPTIONAL - Cookie secure flag
      //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //   secure: environment.production
      // },

      // OPTIONAL - customized storage object
      // storage: new MyStorage(),

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      // clientMetadata: { myCustomKey: 'myCustomValue' },

      // OPTIONAL - Hosted UI configuration
      oauth: {
        domain: `${cognitoURL.getHostAndPort()}`,
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn:  isIdPi ? `${redirectUriBase}/login/federatedIdPi` : `${redirectUriBase}/login/federated`,
        redirectSignOut: `${redirectUriBase}/logout/federated`,
        responseType: 'code',
      },
    });
  }
}
